let hearts = [
  '🥰',
  '😍',
  '😘',
  '💌',
  '❤',
  '💘',
  '💝',
  '💖',
  '💗',
  '💓',
  '💞',
  '💕',
  '❤️‍🔥',
  '💟',
  '❤',
  '🧡',
  '💛',
  '💚',
  '💙',
  '💜',
  '🤎',
  '🖤',
  '🤍'
];

let start = new Date("2021-06-11");
let now = new Date();

let days = Math.floor((now.getTime() - start.getTime()) / (1000 * 3600 * 24));
console.log('Days : ' + days);

let random = new alea('hello.');

let output = '';

for (let i = 0; i <= days; i++) {
  output += hearts[Math.floor(random() * 23)];

  if ((i-2) % 7 === 0) {
    output += ' ';
  }
}

document.getElementById('hearts').innerText = output + '...';

let i = 0;
setInterval(() => {
  i++;
  document.getElementsByTagName('title')[0].innerText = hearts[i % 22] + hearts[i % 22] + hearts[i % 22];
}, 500)
